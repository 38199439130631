// @ts-strict-ignore
import { useState } from 'react';
import { TextLoop } from 'react-text-loop-next';
import Section from 'src/modules/home/visitor/components/section';
import i18n from 'src/utils/translate';
import { performSearch } from 'src/utils/router';
import Button from 'src/components/buttons/button';
import Form from 'src/modules/home/visitor/components/form';

const words = [
  i18n.t('communities'),
  i18n.t('others like you'),
  i18n.t('experiences'),
  i18n.t('treatments'),
  i18n.t('answers'),
  i18n.t('emotional support'),
];

export default function SearchSection() {
  const [search, setSearch] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    performSearch(search);
  };

  return (
    <Section withColumns={true}>
      <h2 className="home__input-title">
        {i18n.t('Find')}
        {' '}
        <span className="home__search-word">
          <TextLoop adjustingSpeed={1000} interval={1500} >
            {words.map((word) => <span key={word}>{word}</span>)}
          </TextLoop>
        </span>
      </h2>
      <Form onSubmit={onSubmit}>
        <input
          aria-label="Search on HealthUnlocked"
          className="form-control home__form-input"
          placeholder={i18n.t('What are you looking for?')}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {' '}
        <Button size="lg" onClick={onSubmit}>
          {i18n.t('Search')}
        </Button>
      </Form>
    </Section>
  );
}

