import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from 'src/modules/home/visitor/components/section';
import config from 'config/config';
import i18n from 'src/utils/translate';
import { getUTCDate } from 'src/utils/date';
import { useSelector } from 'src/store/store';
import { ResponsiveImage } from 'src/components/responsiveImage';

export default function Last30DaysSection() {
  const {
    initialPeopleCount,
    currentPeopleCount,
    initialInteractionCount,
    currentInteractionCount,
    peoplePerSecond,
    interactionsPerSecond,
  } = useSelector(({ pages }) => pages.home.numbers);

  const [peopleCount, setPeopleCount] = useState(currentPeopleCount);
  const [interactionCount, setInteractionCount] = useState(currentInteractionCount);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() < 0.25) setPeopleCount(calculateCurrentNumber(initialPeopleCount, peoplePerSecond));
      if (Math.random() < 0.25) setInteractionCount(calculateCurrentNumber(initialInteractionCount, interactionsPerSecond));
    }, 1000);
    return () => clearInterval(interval);
  }, [initialPeopleCount, initialInteractionCount, interactionsPerSecond, peoplePerSecond]);

  return (
    <Section className="home__last-30" withColumns={true}>
      <div>
        <StyledResponsiveImage
          alt="HealthUnlocked"
          height={77}
          sizes="(max-width: 767px) 252px, 315px"
          src={`${config.cdn.static}img/healthunlocked-logo-green.png`}
          width={827}
        />
        <div className="home__stats-description">{i18n.t('in the last 30 days')}</div>
      </div>
      <div className="home__stats">
        <div className="home__stats-number">{peopleCount}</div>
        <div className="home__stats-subtitle">{i18n.t('people')}</div>
      </div>
      <div className="home__stats">
        <div className="home__stats-number">{interactionCount}</div>
        <div  className="home__stats-subtitle">{i18n.t('interactions')}</div>
      </div>
    </Section>
  );
}

export function calculateCurrentNumber(initialNumber: number, ratePerSecond: number): string {
  const today = getUTCDate();
  const hoursInSeconds = today.getHours() * 3600;
  const minutesInSeconds = today.getMinutes() * 60;
  const secondsInDay = hoursInSeconds + minutesInSeconds + today.getSeconds();
  const currentNumber = initialNumber + secondsInDay * ratePerSecond;
  return Math.floor(currentNumber).toLocaleString();
}

const StyledResponsiveImage = styled(ResponsiveImage)`
  margin-bottom: 15px;
`;
