import styled from 'styled-components';

type Props = {
  id: string;
  title: string;
}
export default function YouTubeEmbed({ id, title }: Props) {
  return (
    <YouTubeEmbedContainer>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen={true}
        loading="lazy"
        src={`https://www.youtube.com/embed/${id}`}
        title={title}
      />
    </YouTubeEmbedContainer>
  );
}

export const YouTubeEmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;
