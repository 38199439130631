import Image, { ImageProps } from 'next/image';
import styled from 'styled-components';

export function ResponsiveImage(props: ImageProps) {
  return (
    <StyledResponsiveImage
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

const StyledResponsiveImage = styled(Image)`
  width: 100%;
  height: auto;
`;

// next/image requires a width and height to be set, if we don't know the size we can use fill and overwrite the styles
export function ResponsiveImageUnknownSize(props: ImageProps) {
  return (
    <Wrapper>
      <StyledResponsiveImageUnknownSize
        fill
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
const StyledResponsiveImageUnknownSize = styled(Image)`
  /* overwrite next/image styles for fill images */
  position: relative !important;
  width: auto !important;
  height: auto !important;
  max-width: 100%;
`;
