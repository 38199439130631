// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import actionTypes from 'src/modules/home/visitor/actionTypes';
import api from 'src/utils/api';
import { calculateCurrentNumber } from 'src/modules/home/visitor/components/last30daysSection';

export const fetchNumbers = () => {
  return async dispatch => {
    dispatch({ type: actionTypes.FETCH_HOME_NUMBERS });

    try {
      const {
        data: { interactionsLast30Days, interactionsLast31Days, peopleLast30Days, peopleLast31Days },
      } = await api.getPublic('stats/homepage');

      const peoplePerDay = peopleLast31Days - peopleLast30Days;
      const peoplePerSecond = peoplePerDay / 24 / 60 / 60;

      const interactionsPerDay = interactionsLast31Days - interactionsLast30Days;
      const interactionsPerSecond = interactionsPerDay / 24 / 60 / 60;

      const numbers = {
        currentInteractionCount: calculateCurrentNumber(interactionsLast30Days, interactionsPerSecond),
        currentPeopleCount: calculateCurrentNumber(peopleLast30Days, peoplePerSecond),
        initialInteractionCount: interactionsLast30Days,
        initialPeopleCount: peopleLast30Days,
        interactionsPerSecond,
        peoplePerSecond,
      };

      dispatch({
        type: actionTypes.FETCH_HOME_NUMBERS_SUCCESS,
        numbers,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({
        type: actionTypes.FETCH_HOME_NUMBERS_FAILURE,
        error: ex,
      });
    }
  };
};
