// @ts-strict-ignore
import { useState } from 'react';
import { useDispatch } from 'src/store/store';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import Form from 'src/modules/home/visitor/components/form';
import Button from 'src/components/buttons/button';

export default function Join({ isSmall }: { isSmall?: boolean }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    trackFootfallEvent(Event.CLICKED, {
      clickMetadata: { section: Section.Join },
      clickType: 'visitor-homepage',
    });
    dispatch(showSignupModal({ defaultEmail: email }));
  };

  return (
    <Form isSmall={isSmall} onSubmit={onSubmit}>
      <input
        aria-label="Please enter your email address"
        className="form-control"
        placeholder={i18n.t('email@address.com')}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button isSubmit={true} size="lg">{i18n.t('Join')}</Button>
    </Form>
  );
}
