import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';

export const days = Array.from(Array(31).keys());

export const years: number[] = [];
const curYear = (new Date()).getFullYear();
for (let j = curYear; j >= (curYear - 120); j = j - 1) {
  years.push(j);
}

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const months = monthNames.map((string, index) => ({
  name: string,
  number: index + 1,
  twoDigits: String(index + 1).padStart(2, '0'),
}));

export const getUTCDate = (dateString?: string): Date => {
  const date = new Date(dateString || Date.now());
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export function formatDateString(str?: string, pattern = 'MMMM yyyy'): string {
  if (!str) return '';
  return format(parseISO(str), pattern);
}
