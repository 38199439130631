import styled, { css } from 'styled-components';
import { textWideSpacing } from 'styles-js/mixins/typography';

export default styled.form<{ isSmall?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;

  input {
    ${textWideSpacing}
    margin-right: 10px;
    padding: 13px 20px 15px 20px;
    height: 50px;
    padding: 0 12px;
    line-height: 50px;

    @media (${({ theme }) => theme.underScreenSmall}) {
      height: 36px;
      line-height: 36px;
    }

    ${({ isSmall }) => isSmall && css`
      width: 350px;

      @media (${({ theme }) => theme.underScreenLarge}) {
        width: 230px;
      }

      @media (${({ theme }) => theme.underScreenSmall}) {
        width: 200px;
      }
    `}
  }
`;
